import "firebase/messaging";
import firebase from "firebase/app";
import localforage from "localforage";
import getConfig from "next/config";
import { notifyMe } from "./notification";
import gql from "graphql-tag";

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

export const initFirebase = () => {
  firebase.initializeApp({
    apiKey: publicRuntimeConfig.FIREBASE_API_KEY,
    authDomain: publicRuntimeConfig.FIREBASE_AUTH_DOMAIN,
    databaseURL: publicRuntimeConfig.FIREBASE_DATABASE_URL,
    projectId: publicRuntimeConfig.FIREBASE_PROJECT_ID,
    storageBucket: publicRuntimeConfig.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: publicRuntimeConfig.FIREBASE_MESSAGGING_SENDER_ID,
    appId: publicRuntimeConfig.FIREBASE_APP_ID,
    measurementId: publicRuntimeConfig.FIREBASE_MEASUREMENT_ID
  });
};

const sendTokenToServer = async (apolloClient, token) => {
  const CREATE_TOKEN = gql`
    mutation CreateFcmToken($token: String!) {
      createFcmToken(token: $token) {
        fcmToken {
          token
        }
      }
    }
  `;
  const res = await apolloClient
    .mutate({
      mutation: CREATE_TOKEN,
      variables: { token: token }
    })
    .then(result => {});
  return res;
};

// tokenInlocalforage: async () => {
//   return localforage.getItem("fcm_token");
// },

// if ((await this.tokenInlocalforage()) !== null) {
//   return false;
// }

export const initMessaging = async apolloClient => {
  if (typeof window !== "undefined" && !window.FIREBASE_INITIALIZED) {
    initFirebase();
    window.FIREBASE_INITIALIZED = true;
  }
  let messaging;
  try {
    messaging = firebase.messaging();
  } catch (error) {
    return;
  }
  await messaging.requestPermission();

  // Get Instance ID token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.
  messaging
    .getToken()
    .then(currentToken => {
      if (currentToken) {
        localforage.setItem("fcm_token", currentToken);
        sendTokenToServer(apolloClient, currentToken);
        // updateUIForPushEnabled(currentToken);
      } else {
        // Show permission request.
        console.log(
          "No Instance ID token available. Request permission to generate one."
        );
        // Show permission UI.
        // updateUIForPushPermissionRequired();
        // setTokenSentToServer(false);
      }
    })
    .catch(err => {
      console.log("An error occurred while retrieving token. ", err);
      // showToken("Error retrieving Instance ID token. ", err);
      // setTokenSentToServer(false);
    });

  // Callback fired if Instance ID token is updated.
  messaging.onTokenRefresh(() => {
    messaging
      .getToken()
      .then(refreshedToken => {
        console.log("Token refreshed.");
        // Indicate that the new Instance ID token has not yet been sent to the
        // app server.
        // setTokenSentToServer(false);
        // Send Instance ID token to app server.
        sendTokenToServer(apolloClient, refreshedToken);
        // ...
      })
      .catch(err => {
        console.log("Unable to retrieve refreshed token ", err);
        // showToken("Unable to retrieve refreshed token ", err);
      });
  });

  // Handle incoming messages. Called when:
  // - a message is received while the app has focus
  // - the user clicks on an app notification created by a service worker
  //   `messaging.setBackgroundMessageHandler` handler.
  messaging.onMessage(payload => {
    console.log("Message received. ", payload);
    // ...
    notifyMe("test");
  });
};
