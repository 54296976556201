import React from "react";
import { Layer, Box, Button, Text } from "grommet";
import redirect from "./redirect";
import {appendOptions} from "../services/CloudinaryService";
import {ccTurquoise} from "../pages/_app";

export const formInputStyleTransparent = {
  border: "1px solid rgba(255,255,255,0.33)",
  padding: "6px 8px 2px 8px",
  width: "auto",
  fontFamily: "HelveticaNeue-Th",
};

export const getExtPlaybackId = ugc => {
  if (
    ugc &&
    ugc.muxVideo &&
    ugc.muxVideo.extPlaybackId &&
    ugc.muxVideo.encodingStatus === "ready"
  ) {
    return ugc.muxVideo.extPlaybackId;
  } else {
    return null;
  }
};

export const camelToUnderscore = (key) => {
  var result = key.replace( /([A-Z])/g, " $1" );
  return result.split(' ').join('_').toLowerCase();
}

export const getAnimatedVideoPreview = (extPlaybackId, end=10, fps=10) => {
  let url = `https://image.mux.com/${extPlaybackId}`;
  if( !extPlaybackId ){
    return `${url}/thumbnail.jpg`;
  }

  return `${url}/animated.gif?start=1&end=${end}&fps=${fps}`;
}

export const getBackground = (activityOrChallenge, animated = false) => {
  let bg;
  const extPlaybackId = getExtPlaybackId(activityOrChallenge.memberVideoBrief);
  if (extPlaybackId) {
    const url = `https://image.mux.com/${activityOrChallenge.memberVideoBrief.muxVideo.extPlaybackId}/`;
    if (animated) bg = url + "animated.gif?start=1&end=5&fps=5";
    else bg = url + "thumbnail.jpg";
  } else if (
    activityOrChallenge.memberVideoBrief &&
    activityOrChallenge.memberVideoBrief.cloudinaryAsset
  ) {
    bg = appendOptions(activityOrChallenge.memberVideoBrief.cloudinaryAsset.secureUrl, ['q_30']);
  } else if (activityOrChallenge.portraitImage) {
    bg = activityOrChallenge.portraitImage;
  } else {
    bg =
      "https://d2yi2bjqgbtxn8.cloudfront.net/activity/Capture_decran_2019-04-09_a_00.01.46.png";
  }
  return bg;
};

export const isBrowser = () => {
  return typeof window !== "undefined";
};

export const copyUrlArgs = (url, argsObj) => {
  let newUrl = url;
  for (let [key, value] of Object.entries(argsObj)) {
    if (key !== "className" && key !== "topic" && key !== "register") {
      if (!newUrl.includes("?")) newUrl += "?";
      else newUrl += "&";
      newUrl += `${key}=${value}`;
    }
  }
  return newUrl;
};

export const queryToProps = (params, query) => {
  for (let [key, value] of Object.entries(query)) {
    if (value && value !== undefined && value !== "undefined") {
      params[key] = value;
    }
  }
  return params;
};

export const nextScreen = props => {
  let url = "/";

  if (props.clubUid && props.activityUid && props.action) {
    url = `/activity/${props.activityUid}?action=${props.action}`;
  } else if(props.clubUid && props.activityUid) {
    url = `/activity/${props.activityUid}`;
  } else if (props.clubUid) {
    if (props.clubUid === 'ugcvisitor') {
      url = `/ugc/${props.ugcUid}&clubUid=ugcvisitor`;
    } else {
      url = `/club/${props.clubUid}`;
    }
  } else if (props.accept) {
    url = `/activity/${props.accept}?accept=${props.accept}`;
  } else if (props.decline) {
    url = `/activity/${props.decline}?decline=${props.decline}`;
  } else if (props.like) {
    url = `/ugc?ugcUid=${props.like}&like=${props.like}`;
  } else if (props.activityUid) {
    url = `/activity/${props.activityUid}`;
  } else if (props.ugcUid) {
    url = `/ugc?ugcUid=${props.ugcUid}`;
  }
  if (props.linkRef) {
    url += url.includes("?") ? "&" : "?";
    url += `linkRef=${props.linkRef}`;
  }

  if (props.joinClub) {
    url += url.includes("?") ? "&" : "?";
    url += `joinClub=${props.joinClub}`;
  }

  redirect({}, url);
};

export class MyLayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: "95vh",
      width: "100vw",
      maxWidth: "720px",
      backgroundColor: "rgba(0,0,0,.1)"
    };
    this.updateHeight = this.updateHeight.bind(this);
  }

  updateHeight() {
    if (isBrowser())
      this.setState({ height: window.innerHeight.toString() + "px" });
  }

  componentDidMount() {
    this.updateHeight();
    window.addEventListener("resize", this.updateHeight);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateHeight);
  }

  render() {
    return (
      <Layer
        {...this.props}
        animate={false}
        responsive={true}
        full={true}
        modal
        plain
        style={{ height: this.state.height, maxWidth: "720px", margin: "auto" }}
      >
        <Box fill background="black">
          {this.props.children}
        </Box>
      </Layer>
    );
  }
}

export class MyButton extends React.Component {
  
  constructor(props) {
    super(props);
    this.setClickableBufferReference = $el => {
      const { clickableBuffer } = this.props;
      if (!clickableBuffer) return;
      if (!$el) return;
      const { top, left, width, height } = $el.getBoundingClientRect();
      const xBoundary = [left - clickableBuffer, left + width + clickableBuffer]
      const yBoundary = [top - clickableBuffer, top + height + clickableBuffer]
      const clickHandler = mouseEvent => {
        const { x, y } = mouseEvent;
        let shouldTrigger = (x >= xBoundary[0] && x <= xBoundary[1]);
        shouldTrigger = shouldTrigger && (y >= yBoundary[0] && y <= yBoundary[1])
        if (shouldTrigger) {
          this.props.onClick(mouseEvent)
        }
      };
      this.clickableBufferListener = window.addEventListener('click', clickHandler);
    };
  }

  componentWillUnmount() {
    if (!this.clickableBufferListener) return;
    window.removeEventListener(this.clickableBufferListener)
  }

  render() {
    return (
      <Button ref={this.setClickableBufferReference}
        plain
        focusIndicator={false}
        {...this.props}
        style={{
          lineHeight: 0,
          textAlign: "center",
          ...this.props.style
        }}
      >
        {this.props.children}
      </Button>
    );
  }
}

export const ButtonTitle = ({ label, size }) => {
  return (
    <Box margin={{ top: "3px" }} align="center" justify="center">
      <Text
        textAlign="center"
        className="HelveticaNeue-BdCn"
        size={size ? size : "medium"}
      >
        {label}
      </Text>
    </Box>
  );
};

export const topDownBackground = {
  backgroundImage:
    "-webkit-linear-gradient(top, rgba(0, 0, 0, 1), #00000000)",
  backgroundImage: "-moz-linear-gradient(top, rgba(0, 0, 0, 1), #00000000)",
  backgroundImage: "-o-linear-gradient(top, rgba(0, 0, 0, 1), #00000000)",
  backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 1), #00000000)"
  // background:
  //   "linear-gradient(180.36deg, rgba(0, 0, 0, 0.2958) 4.16%, rgba(255, 255, 255, 0) 88.78%)"
};

export const downTopBackground = {
  backgroundImage:
    "-webkit-linear-gradient(bottom, rgba(0, 0, 0, 1), #00000000)",
  backgroundImage:
    "-moz-linear-gradient(bottom, rgba(0, 0, 0, 1), #00000000)",
  backgroundImage: "-o-linear-gradient(bottom, rgba(0, 0, 0, 1), #00000000)",
  backgroundImage: "linear-gradient(to top, rgba(0, 0, 0, 1), #00000000)"
};

export const topBarContainer = {
  position: "fixed",
  top: "0",
  zIndex: "2",
  padding: "0 20px",
  paddingTop: "24px",
  maxWidth: "720px",
  margin: "auto",
  backgroundColor: "#1E0E47"
};

export const mediaQueries = (sstyle, breakpoint= "xs") => {
  let _breakpoints = [576, 768, 992, 1200,1400];

  let mqMapping = {
      "xs": 0,
      "sm": 1,
      "md": 2,
      "lg": 3,
      "xl": 4,
      "xxl": 5
}

  let mq = _breakpoints.map(
      bp => `@media (min-width: ${bp}px)`
  )

  return `${mq[mqMapping[breakpoint]]}  { ${sstyle} }`;

}

export const getOrigin = host => {
  let link = "";
  if (host) link = host;
  else if (isBrowser()) link = window.location.origin;
  link = link.replace("www.", "");
  if (!link.startsWith("http")) link = "https://" + link;
  return link;
};
