// append options to cloudinary url, compatible with cloudinary
export const appendOptions = (url, params, failsafe=true ) => {
    let regex = new RegExp('\\b' + 'upload' + '\\b');
    let uploadIndex = url.search(regex);


    if(uploadIndex == -1)
        if( failsafe )
            return url;
        else
            throw "Cloudinary url is unvalid, cannot found 'upload' target";


    return url.substring(0, uploadIndex+6) + '/' + params.join(',') + url.substring(uploadIndex+6, url.length);

}