import { useState, useEffect } from "react";
import { Box, Text } from "grommet";
import {ccBackground} from "../../pages/_app";

const OnlineBanner = () => {
  const [isOnline, setNetwork] = useState(true);
  const updateNetwork = () => {
    if (typeof window !== "undefined") setNetwork(window.navigator.onLine);
  };
  useEffect(() => {
    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);
    return () => {
      window.removeEventListener("offline", updateNetwork);
      window.removeEventListener("online", updateNetwork);
    };
  });
  return isOnline ? (
    <></>
  ) : (
    <Box
      align="center"
      pad="large"
      fill="horizontal"
      background={{ dark: true }}
      style={{
        position: "fixed",
        top: "calc(50vh - 61px)",
        background: `${ccBackground}CC`,
        zIndex:5,
        maxWidth: "720px"
      }}
    >
      <Text textAlign="center" className="HelveticaNeue-Cn" size="large">
          Pas de réseau :  dare.app reprendra dès que la connection sera revenue
      </Text>
    </Box>
  );
};

export default OnlineBanner;
