import ReactGA from "react-ga";
import getConfig from "next/config";

// Only holds serverRuntimeConfig and publicRuntimeConfig from next.config.js nothing else.
const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

export const initGA = () => {
  if (publicRuntimeConfig.GA_TRACKING_ID) {
    console.log("GA init");
    ReactGA.initialize(publicRuntimeConfig.GA_TRACKING_ID);
  }
};

export const logPageView = () => {
  if (publicRuntimeConfig.GA_TRACKING_ID) {
    console.log(`Logging pageview for ${window.location.pathname}`);
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  }
};

export const event = ({ category, action, value }) => {
  if (publicRuntimeConfig.GA_TRACKING_ID) {
    if (category && action) {
      console.log(`Logging event ${category} ${action}`);
      ReactGA.event({ category, action, value });
    }
  }
};

export const logException = (description = "", fatal = false) => {
  if (publicRuntimeConfig.GA_TRACKING_ID) {
    if (description) {
      ReactGA.exception({ description, fatal });
    }
  }
};
