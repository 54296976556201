import React from "react";
import App from "next/app";
import { Grommet } from "grommet";
import { isBrowser } from "../lib/utils";
import Router from "next/router";
import * as Sentry from "@sentry/browser";
import { initGA, logPageView } from "../lib/gtag";
import { initFirebase } from "../lib/firebase";
import "react-phone-number-input/style.css";
import OnlineBanner from "../components/banners/Online";
import "bootstrap/dist/css/bootstrap.min.css";

import "react-datepicker/dist/react-datepicker.css";

Sentry.init({
  dsn: "https://61785c73f34c476094e8ec995977b596@sentry.io/1813990",
});

export const ccTurquoise = "#00f0ff";
export const ccGreen = "#0DF2BB";
export const ccGray = "#505050";
export const ccRed = "#C1272D";
export const ccBackground = "#1E0E47";
export const buttonSize = "72px";
export const placeholder = "https://dare.app/static/img/main_placeholder.jpg";

export const daredareTheme = {
  global: {
    font: { family: "HelveticaNeue-Cn" },
    primary: ccBackground,
    checkbox: {
      toggle: {
        size: "30px",
      },
    },
    colors: {
      ccGreen: ccGreen,
      ccTurquoise: ccTurquoise,
      ccRed: ccRed,
      ccGray: ccGray,
      brand: ccTurquoise,
      focus: "transparent",
      control: { light: ccBackground, dark: ccTurquoise },
      text: { light: "white", dark: "white" },
    },
    focus: {
      border: { color: "transparent" },
    },
  },
  text: {
    xsmall: {
      size: "11px",
      height: "15px",
    },
    small: {
      size: "14px",
      height: "18px",
    },
    medium: {
      size: "18px",
      height: "22px",
    },
    large: {
      size: "22px",
      height: "26px",
    },
    xlarge: {
      size: "28px",
      height: "32px",
    },
    xxlarge: {
      size: "34px",
      height: "38px",
    },
  },
  layer: { background: "transparent" },
  // textInput: {
  //   extend: {
  //     border: 0,
  //     borderBottom: "1px solid rgba(255, 255, 255, 0.5)",
  //     borderRadius: 0,
  //     color: "white !important",
  //     backgroundColor: "transparent !important"
  //   }
  // }
};

class MyApp extends App {
  constructor(props) {
    super(props);
    this.state = {
      height: "100vh",
    };
    this.updateHeight = this.updateHeight.bind(this);
  }

  updateHeight() {
    if (isBrowser())
      this.setState({ height: window.innerHeight.toString() + "px" });
  }

  componentDidMount() {
    this.updateHeight();
    window.addEventListener("resize", this.updateHeight);
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    // if ("serviceWorker" in navigator) {
    //   navigator.serviceWorker
    //     .register("/pull-notification-sw.js")
    //     .catch(err => console.error("Service worker registration failed", err));
    // } else {
    //   console.log("Service worker not supported");
    // }
    if (!window.FIREBASE_INITIALIZED) {
      initFirebase();
      window.FIREBASE_INITIALIZED = true;
    }
    logPageView();
    Router.router.events.on("routeChangeComplete", (url) => {
      window.scrollTo(0, 0);
      logPageView(url);
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateHeight);
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <Grommet
        // full
        theme={daredareTheme}
        // themeMode="dark"
        background={ccBackground}
        style={{
          height: `calc( ${this.state.height} - 1px )`,
          // width: "100%",
          maxWidth: "720px",
          margin: "auto",
          // transition: "all 1s"
        }}
      >
        <OnlineBanner />
        <Component {...pageProps} />
        <style jsx global>{`

            .date-picker-popup {
              /* Your custom styles for the calendar container */
              background-color: #f0f0f0; /* Change the background color */
              border: 1px solid #ccc; /* Change the border color */
              border-radius: 5px;
              padding: 20px;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            }

            .dropdown-toggle::after {
              content: none;
            }

            .bs-popover-bottom .arrow::after {
              border-bottom-color: #1E0E47CC;

            }

            .bs-popover-top .arrow::after {
              border-top-color: #1E0E47CC;
            }

            .bs-popover-right .arrow::after{
              border-right-color: #1E0E47CC;
            }
            
            .flex-grow-2 {
                flex-grow: 2;
            }
            
            .flex-grow-3 {
                flex-grow: 2;
            }

            .bs-popover-left .arrow::after{
              border-left-color: #1E0E47CC;
            }

            h3 {
              margin-bottom:15px;
              font-family: "HelveticaNeue-BdCn";
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }
            .HelveticaNeue-BdCn {
              font-family: "HelveticaNeue-BdCn";
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }
            .HelveticaNeue-BlkCn {
              font-family: "HelveticaNeue-BlkCn";
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }
            .HelveticaNeue-Cn {
              font-family: "HelveticaNeue-Cn";
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }
            .HelveticaNeue-Th {
              font-family: "HelveticaNeue-Th";
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }

            .heebo {
              font-family: "Heebo";
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              font-style: normal;
              font-weight: 300;
            }

            @font-face {
              font-family: "HelveticaNeue-BlkCn";
              src: url("/static/font/HelveticaNeueLTPro-BlkCn.otf")
              format("truetype");
            }
            @font-face {
              font-family: "HelveticaNeue-BdCn";
              src: url("/static/font/HelveticaNeueLTPro-BdCn.otf")
              format("truetype");
            }
            @font-face {
              font-family: "HelveticaNeue-Cn";
              src: url("/static/font/HelveticaNeueLTPro-Cn.otf")
              format("truetype");
            }
            @font-face {
              font-family: "HelveticaNeue-Th";
              src: url("/static/font/HelveticaNeueLTPro-Th.otf")
              format("truetype");
            }

            @font-face {
              font-family: "Heebo";
              src: url("/static/font/Heebo-Regular.ttf") format("truetype");
            }

            .text-light-shadow {
              text-shadow: 0px 0px 10px rgba(97, 97, 97, 0.6);
            }

            .landing-page {
              position: absolute;
              top: 0;
              left: 0;
              width: 100vw;
              height: 100%;

            }
            
             @media only screen and (max-width: 768px) {
              .landing-page .footer-content h3{
                font-style: normal;
                font-weight: 900;
                font-size: 24px;
                line-height: 29px;
              }

              .landing-page .footer h4{
                font-style: normal;
                font-weight: 900;
                font-size: 16px;
              }

            }
            
            .hero .main-title-wrapper {
            position: absolute; /* postulat de départ */
            top: 50%; left: 50%; /* à 50%/50% du parent référent */
            transform: translate(-50%, -50%); /* décalage de 50% de sa propre taille */
            width: auto;
            height: auto;
            text-align: center;
            display: flex;
            flex-direction: column;
            }
            
            .hero .about{
              position: absolute;
             top: 87%;
    right: clamp(19px,8vw,74px);
                
            }
            
                   @media only screen and (max-width: 768px) {
                  .hero .about{
            margin-top: 30px;
            }

            }

            .hero .main-header-wrapper {
              height: 100%;
            }

            .hero .main-cta-wrapper {
            margin-bottom: clamp(60px, 10vh, 150px);
            text-align: center;
            }

            .hero .main-title {
              font-size: 32px;
              width: 100%;
            }

            @media only screen and (max-width: 768px) {
              .hero .main-title {
                font-size: 16px;
                width: 100%;
              }

            }



            .landing-page .feature-2 .hero-content {
              width: 100%;
            }

            .landing-page .spacer {
              height: 100vh;
            }

            .landing-page .footer {
              height: 350px;
              color: #1E0E47;
              background-color: white;
              display: flex;
            }

            @media only screen and (max-width: 768px) {
              .landing-page .footer {
                height: 100vh;
                              color: #1E0E47;
              background-color: white;
              }

            }

            .flex-small-gap{
              gap: 5px;
            }

            .flex-medium-gap{
              gap: 10px;
            }

            .flex-big-gap{
              gap: 20px;
            }

           



            .landing-page .footer-content {
                  width: 100%;
              max-width: 1024px;
              margin: auto;
              display: grid;
              grid-gap: 2rem;
              padding: 2rem 2.5rem;
              
              display: grid;
              height: 75%;
                max-width: 1024px;
                

              grid-template-columns: 1fr 1fr 1fr;
              grid-template-rows: 100%;
            }

            @media only screen and (max-width: 768px) {
              .landing-page .footer-content {
                display: flex;
                justify-content: center;
                flex-direction: column;
                height: 100%;
                gap: 38px;
              }

            }

            .landing-page .hero {
              height: 100vh;
              width: 100vw;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-content: center;
              overflow: hidden;

            }

            @supports (    height: 100svh) {
              .landing-page .main-hero {
                height: 100svh;
              }
            }

            .hero:nth-child(odd)  {
              background-color: white;
              color: #1e0e47;

            }

            .hero .main-hero {
              overflow-x: hidden;
            }

            .hero .hero-content {
              width: 100%;
              max-width: 1024px;
              margin: auto;
              display: grid;
              grid-row-gap: 2rem;
              grid-column-gap: 145px;
              padding: 2rem 1rem;

            }

            @media only screen and (max-width: 768px) {
              .hero .hero-content {
                width: 100%;

                display: grid;
                justify-items: center;
                grid-gap: 1rem;
                align-items: center;
                padding: 20px 30px;


              }
            }

            .hero:nth-child(odd) .hero-content {
              grid-template-areas: 
            "text image"
            "text image"
            "cta  image";

              grid-template-columns: 3fr 1fr;
              grid-template-rows:  1fr 1fr;

            }

            .hero-content .text-wrapper{
              width: 100%;

            }

            @media only screen and (max-width: 768px) {
              .hero-content .text-wrapper{
                width:75vw;
                margin: auto;

              }
            }

            @media only screen and (max-width: 768px) {
              .hero:nth-child(odd) .hero-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: clamp(10px, 20vh,15px);
                margin: auto;

              }
              
                            .hero:nth-child(even) .hero-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                                gap: clamp(15px, 20vh,20px);

                margin: auto;

              }
            }



            .hero-content .image {
              grid-area: image;
                overflow: hidden;
            }

            @media only screen and (max-width: 768px) {
              .hero-content .image {
                width: clamp(80px, 20vh,161px);
                overflow: hidden;
                margin-bottom: 5px;
              }
            }

            .hero-content .title {
              grid-area: title;
            }

            .hero-content .text {
              grid-area: text;


            }

            .landing-page h2 {
              font-family: "HelveticaNeue-BlkCn";
              font-size: 28px
              text-align: justify;
            }

            @media only screen and (max-width: 768px) {
              .landing-page h2 {
                font-family: "HelveticaNeue-BlkCn";
                font-size: 16px;
                margin-bottom: 10px;
              text-align: justify;
                
              }
            }

            .landing-page h1 {
              font-family: "HelveticaNeue-BlkCn";
              font-size: 32px;
              margin-bottom: 25px;
              

            }

            @media only screen and (max-width: 768px) {
              .landing-page h1 {
                font-size: 32px;
                margin-bottom: 10px;

              }
            }
            
            @media only screen and (max-width: 400px) {
              .landing-page h1 {
                font-size: 24px;
                margin-bottom: 10px;

              }
              
              .cta{
                margin-top: 0px!important;
              }
            }

            @media  (max-height: 540px) {
              .hero-content{
                gap: 7px!important;
              }
              .landing-page h3 {
                margin-bottom: 5px!important;

              }
            }

            .hero .landing-page h3 {
              font-family: "HelveticaNeue-BdCn";
              font-size: 28px;
              margin-bottom: 40px;
              text-align: justify;
              
            }

            @media only screen and (max-width: 768px) {
              .landing-page h3 {
                font-size: 16px;
                margin-bottom: 25px;
              text-align: justify;
                
              }
            }

            .hero-content .cta {
              grid-area: cta;
              display: flex;
              justify-content: start;
              align-items: center;
            }

                
            .hero:nth-child(even) .hero-content {
                          grid-template-areas: 
            "image text "
            "image text "
            "image cta  ";

              grid-template-columns:  1fr 3fr;
              grid-template-rows:  1fr 1fr;

            }

            .hero:nth-child(even)  {
              background-color: #1e0e47;
              color:  white;


            }

            body {
              margin: 0;
              background-color: #1e0e47;
            }
            a {
              color: white;
            }
            *:focus {
              outline: none;
            }
            select {
              background: transparent;
              color: white;
              border: 0px;
              font-family: HelveticaNeue-BdCn;
              font-size: 22px;
            }
            .PhoneInputInput {
              color: black;
              font-family: "HelveticaNeue-Th";
              font-size: 20px;
              text-align: center;
              height: 30px;
              border-radius: 4px;
              border: 0px;
            }

            :root {
              --PhoneInputCountryFlag-height: 30px;
            }
          `}</style>
      </Grommet>
    );
  }
}

export default MyApp;
